import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Seite nicht gefunden" />
    <div className="content">

    <h1>SEITE NICHT GEFUNDEN</h1>
    <p>Diese Seite existiert leider nicht. <a href={'/'}>Zur Startseite</a></p>
    </div>
  </Layout>
)

export default NotFoundPage
